<template>
  <div>
    <loading
      :active.sync="isLoading"
      color="#f8a229"
      loader="dots"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <v-card-text>
      <v-container class="row1">
        <v-row>
          <v-col cols="14" sm="2" md="2">
            <v-row dense class="mb-5">
              <router-link
                v-bind:to="'/admin/admin-departments/' + mainInfo.departmentId"
                style="text-decoration: none"
              >
                <v-btn color="secondary">
                  <v-icon color="black"> mdi-arrow-left </v-icon>
                </v-btn>
              </router-link>
            </v-row></v-col
          >
          <v-col cols="14" sm="6" md="10">
            <v-row dense justify="end" class="mb-5">
              <div>
                <v-btn color="secondary" dark @click="dialog = true">
                  <v-icon color="black"> mdi-plus-circle </v-icon>
                  <v-card-text class="black--text font-weight-bold">
                    Add New Product
                  </v-card-text>
                </v-btn>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <!-- <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          style="margin-bottom:10px; margin-top:-35px;"
        >
        </v-text-field> -->
        <v-row>
          <v-col cols="14" sm="12" md="8">
            <v-row dense class="itemsLayout">
              <v-col lg="3" md="4" sm="4" xs="4" class="pb-3">
                <v-card height="200" width="150" @click="dialog = true">
                  <v-card-text class="text-center">
                    <v-icon
                      color="secondary"
                      style="font-size: 70px"
                      class="mt-11"
                    >
                      mdi-plus
                    </v-icon>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col
                v-for="item in dataFiltered"
                :key="item.productId"
                lg="3"
                md="4"
                sm="4"
                xs="4"
                class="pb-3"
              >
                <v-card height="200" width="150" class="overflow-hidden">
                  <v-img
                    v-if="!item.itemPic"
                    class="white--text align-end"
                    gradient="rgba(248,162,41,.5), rgba(0,0,0,.2)"
                    color="red"
                    width="150"
                    height="100"
                  >
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div style="margin-top: 0px; text-align: center">
                          <v-icon
                            class="text-h1"
                            color="white"
                            id="imageButton"
                            @click="addImage(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-image-plus
                          </v-icon>
                        </div>
                      </template>
                      <span>Add Image</span>
                    </v-tooltip>

                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            margin-top: -76px;
                            float: left;
                            background-color: white;
                            border-radius: 0px 10px 10px 0px;
                          "
                        >
                          <v-icon
                            color="red"
                            @click="deleteItem(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-delete-circle
                          </v-icon>
                        </div>
                      </template>
                      <span>Delete {{ item.departmentName }}</span>
                    </v-tooltip>

                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            float: right;
                            background-color: white;
                            border-radius: 15px 0px 0px 0px;
                          "
                        >
                          <v-icon
                            color="black"
                            @click="viewEditItem(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-information
                          </v-icon>
                        </div>
                      </template>
                      <span>More Info</span>
                    </v-tooltip>
                  </v-img>
                  <v-img
                    v-if="item.itemPic"
                    :src="$url + item.itemPic"
                    class="white--text align-end"
                    width="150"
                    height="100"
                  >
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            margin-top: -76px;
                            float: left;
                            background-color: white;
                            border-radius: 0px 10px 10px 0px;
                          "
                        >
                          <v-icon
                            color="red"
                            @click="deleteItem(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-delete-circle
                          </v-icon>
                        </div>
                      </template>
                      <span>Delete {{ item.productName }}</span>
                    </v-tooltip>

                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            float: right;
                            background-color: white;
                            border-radius: 15px 0px 0px 0px;
                          "
                        >
                          <v-icon
                            color="black"
                            @click="viewEditItem(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-information
                          </v-icon>
                        </div>
                      </template>
                      <span>More Info</span>
                    </v-tooltip>
                  </v-img>
                  <router-link
                    v-bind:to="{
                      path: '/admin/admin-products/' + item.productId
                    }"
                    style="text-decoration: none"
                  >
                    <v-card-text style="cursor: pointer" class="black--text">
                      <div class="headerClass">
                        <v-icon style="font-size: 20px" class="mb-1">
                          mdi-food
                        </v-icon>

                        {{ item.productName }}
                      </div>
                      <v-row>
                        <v-col>
                          <div
                            v-if="!item.isQuickMenu"
                            class="headerClass"
                            style="font-size: 12px"
                          >
                            ${{ formatPrice(getRetailPrice(item)) }}
                          </div>

                          <!-- <div
                            v-if="
                              !item.isQuickMenu &&
                                ((orderType == 2 && item.dinePrice == 0) ||
                                  (orderType == 1 && item.deliveryPrice == 0) ||
                                  (orderType == 0 && item.takeawayPrice == 0))
                            "
                            class="headerClass"
                            style="font-size:12px"
                          >
                            ${{ formatPrice(item.retailPrice) }}
                          </div> -->

                          <div
                            v-if="item.isQuickMenu"
                            class="headerClass"
                            style="
                              text-decoration: line-through;
                              color: red;
                              font-size: 12px;
                            "
                          >
                            ${{ formatPrice(getRetailPrice(item)) }}
                          </div>

                          <div
                            v-if="item.isQuickMenu"
                            class="headerClass"
                            style="font-size: 12px"
                          >
                            ${{ formatPrice(getDiscountPrice(item)) }}
                            <v-icon
                              style="font-size: 15px; margin-top: -5px"
                              color="primary"
                            >
                              mdi-sale
                            </v-icon>
                          </div>

                          <!-- <div
                            v-if="
                              !item.isQuickMenu &&
                                orderType == 2 &&
                                item.dinePrice != 0
                            "
                            class="headerClass"
                            style="font-size:12px"
                          >
                            ${{ formatPrice(item.dinePrice) }}
                            <v-icon
                              style="font-size:15px; margin-top:-5px;"
                              color="primary"
                            >
                              mdi-food-fork-drink
                            </v-icon>
                          </div>

                          <div
                            v-if="
                              !item.isQuickMenu &&
                                orderType == 1 &&
                                item.deliveryPrice != 0
                            "
                            class="headerClass"
                            style="font-size:12px"
                          >
                            ${{ formatPrice(item.deliveryPrice) }}
                            <v-icon
                              style="font-size:15px; margin-top:-5px;"
                              color="primary"
                            >
                              mdi-bike-fast
                            </v-icon>
                          </div>

                          <div
                            v-if="
                              !item.isQuickMenu &&
                                orderType == 0 &&
                                item.takeawayPrice != 0
                            "
                            class="headerClass"
                            style="font-size:12px"
                          >
                            ${{ formatPrice(item.takeawayPrice) }}
                            <v-icon
                              style="font-size:15px; margin-top:-5px;"
                              color="primary"
                            >
                              mdi-food-takeout-box
                            </v-icon>
                          </div>  -->
                        </v-col>

                        <v-col>
                          <div>
                            <qrcode-vue
                              style="float: right"
                              v-if="item.barcode"
                              render-as="svg"
                              :value="item.barcode"
                              size="30"
                              level="H"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </router-link>
                </v-card>
              </v-col>
            </v-row>
            <v-col cols="14" sm="12" md="12">
              <v-pagination
                v-model="page"
                :length="pageCount"
                @click="changePage"
                circle
                color="black"
              ></v-pagination>
            </v-col>
          </v-col>
          <v-col cols="12" md="4">
            <v-card>
              <v-row style="float: right; margin-top: 10px; margin-right: 10px">
                <v-tooltip top color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="secondary"
                      @click="editMainInfo()"
                      style="margin-right: 10px"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-circle-edit-outline
                    </v-icon>
                  </template>
                  <span>Edit {{ mainInfo.subDepartmentName }}</span>
                </v-tooltip>

                <v-tooltip top color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <div>
                      <v-icon
                        color="red"
                        @click="deleteMainInfo()"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete-circle
                      </v-icon>
                    </div>
                  </template>
                  <span>Delete {{ mainInfo.subDepartmentName }}</span>
                </v-tooltip>
              </v-row>
              <v-card-title style="font-size: 20px">
                Category Info
              </v-card-title>

              <v-card-title
                style="
                  font-size: 18px;
                  font-weight: bold;
                  text-transform: uppercase;
                "
              >
                {{ mainInfo.subDepartmentName }}
              </v-card-title>
              <v-divider class="mx-4"></v-divider>

              <v-card-text>
                No of Products :
                {{ countItems }}
              </v-card-text>
              <v-card-text>
                Note :
                {{ mainInfo.note }}
              </v-card-text>

              <v-card-text>
                <v-chip
                  label
                  color="green"
                  class="white--text"
                  v-if="mainInfo.isInactive === false"
                >
                  Active
                </v-chip>
                <v-chip
                  label
                  color="red"
                  class="white--text"
                  v-if="mainInfo.isInactive === true"
                >
                  In Active
                </v-chip>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-col cols="14" sm="8" md="4" class="float-right mt-n15">
          <v-row dense class="mb-5 float-right">
            <v-btn color="secondary" @click="dialogTrash = true">
              <v-icon color="black"> mdi-delete-restore </v-icon>
            </v-btn>
          </v-row></v-col
        >
      </v-container>
      <v-dialog v-model="dialogViewItem" max-width="500px" origin="top right">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="dialogViewItem = false">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title style="font-size: 20px">
            Product Info
            <v-tooltip right color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="secondary"
                  @click="editItem()"
                  style="margin-left: 10px"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-circle-edit-outline
                </v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip right color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="secondary"
                  @click="editImage()"
                  style="margin-left: 10px"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-image-plus
                </v-icon>
              </template>
              <span>Edit Image</span>
            </v-tooltip>
          </v-card-title>

          <v-card-title
            style="
              font-size: 18px;
              font-weight: bold;
              text-transform: uppercase;
            "
          >
            {{ viewItem.productName }}
          </v-card-title>
          <v-card-title v-if="viewItem.productDescription != ''">
            {{ viewItem.productDescription }}
          </v-card-title>
          <v-divider class="mx-4"></v-divider>

          <v-col md="12">
            <v-row>
              <v-col md="8">
                <v-card-text>
                  <div>Price : {{ formatPrice(viewItem.retailPrice) }}</div>
                  <!-- <div>Dine Price : {{ formatPrice(viewItem.dinePrice) }}</div>
                  <div>
                    Takeaway Price : {{ formatPrice(viewItem.takeawayPrice) }}
                  </div>
                  <div>
                    Delivery Price : {{ formatPrice(viewItem.dinePrice) }}
                  </div> -->
                  <div>
                    Discount Price : {{ formatPrice(viewItem.discountPrice) }}
                  </div>
                </v-card-text>
              </v-col>
              <v-col md="4">
                <v-card-text>
                  <div>
                    <qrcode-vue
                      v-if="viewItem.barcode"
                      render-as="canvas"
                      :value="viewItem.barcode"
                      size="80"
                      level="H"
                    /></div
                ></v-card-text>
              </v-col> </v-row
          ></v-col>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialog"
        :max-width="titleChange == -1 ? '700px' : '500px'"
        origin="top right"
      >
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="close">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="editedForm">
                <v-row>
                  <v-col>
                    <v-col cols="12" class="mb-n8">
                      <v-select
                        v-model="editedItem.subDepartmentId"
                        label="Category"
                        :items="subDepartments"
                        item-text="subDepartmentName"
                        item-value="subDepartmentId"
                        outlined
                        clearable
                      />
                    </v-col>

                    <v-col cols="12" class="mb-n8">
                      <v-select
                        v-model="editedItem.unitId"
                        label="Unit"
                        :items="units"
                        item-text="unitName"
                        item-value="unitID"
                        outlined
                        clearable
                      />
                    </v-col>

                    <v-col cols="12" class="mb-n8">
                      <v-text-field
                        type="number"
                        class="numberInput"
                        v-model="editedItem.barcode"
                        label="EAN"
                        outlined
                        clearable
                        maxlength="13"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      />
                    </v-col>

                    <v-col cols="12" class="mb-n8">
                      <v-text-field
                        v-model="editedItem.productName"
                        label="Product Name"
                        outlined
                        clearable
                      />
                    </v-col>
                    <v-col cols="12" class="mb-n8">
                      <v-text-field
                        v-model="editedItem.productDescription"
                        label="Product Description"
                        outlined
                        clearable
                      />
                    </v-col>
                    <v-col v-if="titleChange == -1" cols="12" class="mb-n8">
                      <v-file-input
                        v-model="editedItem.formFile"
                        accept="image/png, image/jpeg, image/bmp"
                        prepend-icon="mdi-camera"
                        label="Image"
                        outlined
                        clearable
                      />
                    </v-col>
                  </v-col>
                  <v-col v-if="titleChange == -1">
                    <v-col cols="12" class="mb-n8">
                      <v-text-field
                        type="number"
                        class="numberInput"
                        v-model="editedItem.retailPrice"
                        label="Price"
                        outlined
                        clearable
                      />
                    </v-col>
                    <v-col v-if="priceVariation" cols="12" class="mb-n8">
                      <v-text-field
                        type="number"
                        class="numberInput"
                        v-model="editedItem.takeawayPrice"
                        label="Takeaway Price"
                        outlined
                        clearable
                      />
                    </v-col>
                    <v-col v-if="priceVariation" cols="12" class="mb-n8">
                      <v-text-field
                        type="number"
                        class="numberInput"
                        v-model="editedItem.dinePrice"
                        label="Dine Price"
                        outlined
                        clearable
                      />
                    </v-col>
                    <v-col v-if="priceVariation" cols="12" class="mb-n8">
                      <v-text-field
                        type="number"
                        class="numberInput"
                        v-model="editedItem.deliveryPrice"
                        label="Delivery Price"
                        outlined
                        clearable
                      />
                    </v-col>
                    <v-col cols="12" class="mb-n8">
                      <v-text-field
                        type="number"
                        class="numberInput"
                        v-model="editedItem.discountPrice"
                        label="Discount Price"
                        outlined
                        clearable
                      />
                    </v-col>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="validate"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogImage" max-width="500px" origin="top right">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="dialogImage = false">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title>
            <span class="headline"> Add Image</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="imageForm">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="viewItem.productName"
                      label="Product"
                      outlined
                      readonly
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-file-input
                      id="imageFile"
                      v-model="editedImage.formFile"
                      accept="image/png, image/jpeg, image/bmp"
                      prepend-icon="mdi-camera"
                      label="Image"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <!-- <v-btn color="blue darken-1" text @click="close">
              Cancel
            </v-btn> -->
            <v-btn color="blue darken-1" text @click="dialogImage = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="addImageToDb">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="mainInfoEdit" max-width="500px" origin="top right">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="mainInfoEdit = false">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title>
            <span class="headline">Edit Category</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="editedForm">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedMainInfo.subDepartmentName"
                      label="Department Name"
                      outlined
                      clearable
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-select
                      v-model="editedMainInfo.isInactive"
                      label="Status"
                      :item-text="text"
                      :item-value="value"
                      :items="status"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="mainInfoEdit = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveEditMainInfo">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDeleteMainInfo" max-width="500px">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="closeDelete">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title class="headline">
            Are you sure you want to delete {{ mainInfo.subDepartmentName }}?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="trashMainInfo">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogTrash" max-width="1200">
        <v-card class="pa-10">
          <v-row style="float: right">
            <div>
              <v-icon color="closeButton" @click="dialogTrash = false">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          >
          </v-text-field>

          <v-data-table
            :headers="headers"
            :items="trashed"
            :items-per-page="5"
            :search="search"
          >
            <template v-slot:[`item.restore`]="{ item }">
              <v-icon color="secondary" @click="restoreItem(item)">
                mdi-restore
              </v-icon>
            </template>

            <template v-slot:[`item.delete`]="{ item }">
              <v-icon color="#FF0000" @click="permanentDelete(item)">
                mdi-trash-can-outline
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogRestore" max-width="500px">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="closeRestore">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title class="headline">
            Are you sure you want to restore
            {{ editedItem.productName }} ?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeRestore">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="restoreItemConfm">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="closeDelete">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title class="headline">
            Are you sure you want to delete {{ editedItem.productName }}?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="trashItem"> OK </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogPermanentDelete" max-width="500px">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="closeDelete">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title class="headline">
            Are you sure you want to delete
            {{ editedItem.productName }} permanently?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </div>
</template>

<style>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
  font-weight: bold;
}
.row1 {
  margin-top: -25px;
}
.itemsLayout {
  height: 435px;
  overflow: hidden;
  overflow-y: auto;
}
.addbutton {
  float: right;
}
.temp {
  background-color: rgb(255, 255, 255);
}
.numberInput input[type="number"] {
  -moz-appearance: textfield;
}
.numberInput input::-webkit-outer-spin-button,
.numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import MaterialCard from "../components/MaterialCard.vue";

import QrcodeVue from "qrcode.vue";
import Vue from "vue";
Vue.component("qrcode-vue", QrcodeVue);

export default {
  components: { MaterialCard, Loading },
  data() {
    return {
      isLoading: false,
      loadingSize: 50,
      loadingFullPage: true,
      loading: false,
      dialogViewItem: false,
      viewItem: [],
      mainInfo: {},
      editedMainInfo: {},
      mainInfoEdit: false,
      dialogDeleteMainInfo: false,

      countItems: "",

      dialogTrash: false,
      dialog: false,
      dialogDelete: false,
      dialogPermanentDelete: false,
      dialogRestore: false,
      dateModal: false,
      search: "",

      headers: [
        { text: "Product Id", value: "productId" },
        { text: "Department Id", value: "departmentId" },
        { text: "Sub Department Id", value: "subDepartmentId" },
        { text: "Unit Id", value: "unitId" },
        { text: "Product Name", value: "productName" },
        { text: "", value: "restore" },
        { text: "", value: "delete" }
      ],
      products: [],
      status: [
        { text: "Active ", value: false },
        { text: "InActitve ", value: true }
      ],
      titleChange: -1,
      editedIndexFiltered: "",
      editedIndexProducts: "",
      editedItem: {
        productName: "",
        productDescription: "",
        subDepartmentId: null,
        departmentId: null,
        unitId: "",
        retailPrice: "",
        takeawayPrice: 0,
        dinePrice: 0,
        deliveryPrice: 0,
        barcode: "",
        formFile: null
      },
      defaultItem: {
        productName: "",
        subDepartmentId: null,
        departmentId: null,
        unitId: "",
        retailPrice: "",
        takeawayPrice: 0,
        dinePrice: 0,
        deliveryPrice: 0,
        barcode: "",
        formFile: null
      },
      departmentId: "",
      subDepartmentId: "",
      units: [],
      subDepartments: [],

      trashed: [],
      page: 1,
      pageCount: null,
      start: 0,
      end: 7,
      dataFiltered: [],

      filtered: {
        orderBy: "",
        orderDirection: "desc",
        skip: 0,
        take: 7,
        searchValue: "",
        searchColumn: "",
        clubId: ""
      },
      formData: null,

      dialogImage: false,
      editedImage: {
        departmentId: "",
        formFile: ""
      },
      imageFormData: null,
      priceVariation: false,
      subDeptOld: 0
    };
  },

  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Product" : "Edit Product";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogPermanentDelete(val) {
      val || this.closeDelete();
    },
    dialogRestore(val) {
      val || this.closeRestore();
    },
    page: function() {
      this.changePage();
    }
  },

  created() {
    this.getDataFromApi();
    //  this.getFilteredData();
  },

  methods: {
    getRetailPrice(item) {
      return item.retailPrice;
      // if (item.unitId == "1") {
      //   return item.retailPrice * 1000;
      // } else {
      //   return item.retailPrice;
      // }
    },
    getDiscountPrice(item) {
      return item.discountPrice;
      // if (item.unitId == "1") {
      //   return item.discountPrice * 1000;
      // } else {
      //   return item.discountPrice;
      // }
    },
    imageEnlarge: function(id) {
      console.log("Hover");
      document.getElementById(id).size = "20";
    },
    qrCodeClick() {
      console.log("Clicked");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getDataFromApi() {
      this.isLoading = true;
      var currentUrl = window.location.pathname;
      let vars = currentUrl.split("/");
      vars.reverse();
      let itemId = vars[0];
      itemId = parseInt(itemId);

      this.subDepartmentId = itemId;
      this.editedItem.subDepartmentId = itemId;

      this.$http
        //  .get("/ProductMaster/GetBySubDepartmentId/" + itemId)
        .get("/ProductMaster")
        .then(response => {
          console.log(response.data.data);
          // this.products = response.data.data
          this.products = response.data.data.filter(
            a => a.subDepartmentId === itemId
          );

          this.products = this.products.reverse();
          this.$http.get("/ProductMaster/GetTrashed").then(response => {
            this.trashed = response.data.data.filter(
              a => a.subDepartmentId === itemId
            );
          });
          this.changePage();
        })
        .catch(error => {
          console.log("Error");
          if (error.response.status) {
            this.$toast.error("Error in getting Data", "Error", {
              position: "topRight"
            });
            this.isLoading = false;
          }
        });

      this.$http.get(`/SubDepartment/${itemId}`).then(response => {
        this.mainInfo = response.data.data;
        this.editedItem.departmentId = this.mainInfo.departmentId;
        this.departmentId = this.mainInfo.departmentId;
      });

      this.$http.get("/Unites/").then(response => {
        this.units = response.data.data;
      });

      this.$http.get("/Menus/").then(response => {
        this.subDepartments = response.data;
      });
    },
    // getFilteredData() {
    //   this.$http
    //     .post("/Product/GetAllByFilter", this.filtered)
    //     .then(response => {
    //       this.dataFiltered = response.data.data;
    //     });
    // },
    updateData() {
      //this.page = 1;
      this.changePage();
    },
    changePage() {
      // var diff = this.page - 1;
      // this.filtered.skip = this.start + this.end * diff;
      // this.getFilteredData();

      var count = this.products.length;
      var float = count / this.end;
      var int = parseInt(count / this.end);
      if (float > int) {
        this.pageCount = int + 1;
      } else {
        this.pageCount = int;
      }

      var diff = this.page - 1;
      var newStart = this.start + this.end * diff;
      var newEnd = this.end + this.end * diff;

      const items = this.products.slice(newStart, newEnd);

      this.dataFiltered = items;
      this.countItems = this.products.length;
      this.isLoading = false;
    },
    viewEditItem(item) {
      this.editedIndexFiltered = this.dataFiltered.indexOf(item);
      this.editedIndexProducts = this.products.indexOf(item);
      this.viewItem = Object.assign({}, item);
      this.subDeptOld = this.viewItem.subDepartmentId;
      // var unitName = this.getUnit(this.viewItem.unitId);
      // if (unitName == "Kg") {
      //   this.viewItem.retailPrice = this.viewItem.retailPrice * 1000;
      //   this.viewItem.discountPrice = this.viewItem.discountPrice * 1000;
      // }

      this.dialogViewItem = true;
    },
    editItem() {
      this.editedItem = Object.assign({}, this.viewItem);
      this.titleChange = 0;
      this.dialog = true;
      console.log(" this.editedIndexFiltered", this.editedIndexFiltered);
    },
    editMainInfo() {
      this.editedMainInfo = Object.assign({}, this.mainInfo);
      this.mainInfoEdit = true;
    },
    deleteMainInfo() {
      this.dialogDeleteMainInfo = true;
    },
    deleteItem(item) {
      this.editedIndexFiltered = this.dataFiltered.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    restoreItem(item) {
      this.editedIndexFiltered = this.trashed.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogRestore = true;
    },
    permanentDelete(item) {
      this.editedIndexFiltered = this.trashed.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogPermanentDelete = true;
    },

    trashItem() {
      this.isLoading = true;
      this.$http
        .post("/ProductMaster/Trash", this.editedItem)
        .then(response => {
          console.log(response);
          if (response.data.success === true) {
            this.$toast.success("Deleted successfully.", "Success", {
              position: "topRight"
            });
            this.products.splice(this.editedIndexFiltered, 1);
            this.trashed.unshift(this.editedItem);
            this.updateData();
            this.closeDelete();
          } else {
            this.isLoading = false;
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.isLoading = false;
            this.$toast.error(" Deleting Process Failed", "Error", {
              position: "topRight"
            });
          }
        });
    },

    restoreItemConfm() {
      this.isLoading = true;
      this.$http
        .post("/ProductMaster/Restore", this.editedItem)
        .then(response => {
          if (response.data.success === true) {
            this.trashed.splice(this.editedIndexFiltered, 1);

            this.products.unshift(response.data.data);
            this.updateData();
            this.$toast.success("Restore successfully.", "Success", {
              position: "topRight"
            });
          } else {
            this.isLoading = false;
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.isLoading = false;
            this.$toast.error(" Restore Process Failed", "Error", {
              position: "topRight"
            });
          }
        });

      this.dialogRestore = false;
    },
    deleteItemConfirm() {
      this.isLoading = true;
      var itemId = this.editedItem.productId;
      this.$http
        .delete("/ProductMaster/" + itemId)
        .then(response => {
          if (response.data.success === true) {
            this.trashed.splice(this.editedIndexFiltered, 1);
            this.isLoading = false;
            this.$toast.success("Deleted Permanently.", "Success", {
              position: "topRight"
            });
          }
        })

        .catch(error => {
          if (error.response.status === 404) {
            this.isLoading = false;
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        });
      this.closeDelete();
    },
    setDefault() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.subDepartmentId = this.subDepartmentId;
        this.editedItem.departmentId = this.departmentId;
        this.titleChange = -1;
      });
    },
    close() {
      this.setDefault();
      this.dialog = false;
      this.priceVariation = false;
    },

    closeDelete() {
      this.setDefault();
      this.dialogDeleteMainInfo = false;
      this.dialogDelete = false;
      this.dialogPermanentDelete = false;
    },

    closeRestore() {
      this.setDefault();
      this.dialogRestore = false;
    },
    getUnit(id) {
      var unit = this.units.find(e => e.unitID == id);
      if (unit) {
        return unit.unitName;
      } else {
        return "";
      }
    },

    validate() {
      if (
        this.editedItem.productName &&
        this.editedItem.retailPrice &&
        this.editedItem.unitId
      ) {
        // var unitName = this.getUnit(this.editedItem.unitId);
        // if (this.editedItem.productDescription == "") {
        //   this.editedItem.productDescription = unitName;
        // }
        // if (unitName == "Kg") {
        //   this.editedItem.retailPrice = this.editedItem.retailPrice / 1000;
        //   this.editedItem.discountPrice = this.editedItem.discountPrice / 1000;
        // }

        this.createFormData();
      } else {
        this.$toast.error("Please Fill the Form", "Error", {
          position: "topRight"
        });
      }
    },
    createFormData() {
      this.formData = new FormData();

      this.formData.append("productName", this.editedItem.productName);
      this.formData.append(
        "productDescription",
        this.editedItem.productDescription
      );
      this.formData.append("departmentId", this.editedItem.departmentId);
      this.formData.append("subDepartmentId", this.editedItem.subDepartmentId);
      this.formData.append("unitId", this.editedItem.unitId);
      this.formData.append("retailPrice", this.editedItem.retailPrice);
      this.formData.append("takeawayPrice", this.editedItem.takeawayPrice);
      this.formData.append("dinePrice", this.editedItem.dinePrice);
      this.formData.append("deliveryPrice", this.editedItem.deliveryPrice);
      this.formData.append("barcode", this.editedItem.barcode);
      this.formData.append("formFile", this.editedItem.formFile);

      // Display the key/value pairs

      for (var pair of this.formData.entries()) {
        console.log(pair[0] + ":" + pair[1]);
      }

      this.save();
    },
    save() {
      this.isLoading = true;
      if (this.titleChange === -1) {
        this.$http
          .post("/ProductMaster", this.formData)
          .then(response => {
            if (response.data.success === true) {
              console.log(response);
              this.products.unshift(response.data.data);
              this.updateData();
              this.$toast.success("Product added successfully.", "Success", {
                position: "topRight"
              });
            } else {
              this.isLoading = false;
              this.$toast.error(response.data.message, "Error", {
                position: "topRight"
              });
            }
          })
          .catch(error => {
            console.log("error", error);
            if (error.response.status) {
              this.isLoading = false;
              this.$toast.error("Adding Process Failed", "Error", {
                position: "topRight"
              });
            }
          });
      } else {
        var itemId = this.editedItem.productId;
        this.$http
          .put("/ProductMaster/" + itemId, this.editedItem)
          .then(response => {
            if (response.data.success === true) {
              var count = this.products.length;
              this.viewItem = Object.assign({}, response.data.data);

              //this.products.splice(this.editedIndexFiltered, 1);

              // this.products.unshift(response.data.data);

              var subDeptnew = this.viewItem.subDepartmentId;
              console.log(this.subDeptOld, subDeptnew);

              if (this.subDeptOld == subDeptnew) {
                this.products.splice(
                  this.editedIndexProducts,
                  1,
                  response.data.data
                );

                this.dataFiltered.splice(
                  this.editedIndexFiltered,
                  1,
                  response.data.data
                );
              } else {
                this.products.splice(this.editedIndexProducts, 1);
                this.dataFiltered.splice(this.editedIndexFiltered, 1);
                this.dialogViewItem = false;
              }

              this.isLoading = false;
              var newCount = this.products.length;
              console.log(count, newCount);
              this.updateData();

              this.$toast.success("Product Edited successfully.", "Success", {
                position: "topRight"
              });
            } else {
              this.isLoading = false;
              this.$toast.error(response.data.message, "Error", {
                position: "topRight"
              });
            }
          })
          .catch(error => {
            if (error.response.status) {
              this.isLoading = false;
              this.$toast.error("Editing Process Failed", "Error", {
                position: "topRight"
              });
            }
          });
      }

      this.close();
    },
    saveEditMainInfo() {
      console.log(this.editedMainInfo);

      if (
        this.editedMainInfo.subDepartmentName != "" &&
        this.editedMainInfo.isInactive != null
      ) {
        this.isLoading = true;
        var itemId = this.editedMainInfo.subDepartmentId;
        this.$http
          .put("/SubDepartment/" + itemId, this.editedMainInfo)
          .then(response => {
            if (response.data.success === true) {
              this.mainInfo = response.data.data;
              this.isLoading = false;
              this.$toast.success(
                "Sub Department Edited successfully.",
                "Success",
                {
                  position: "topRight"
                }
              );
            } else {
              this.isLoading = false;
              this.$toast.error(response.data.message, "Error", {
                position: "topRight"
              });
            }
          })
          .catch(error => {
            if (error.response.status) {
              this.isLoading = false;
              this.$toast.error("Editing Process Failed", "Error", {
                position: "topRight"
              });
            }
          });
        this.mainInfoEdit = false;
      } else {
        this.$toast.error("Please Fill the Form", "Error", {
          position: "topRight"
        });
      }
    },
    trashMainInfo() {
      this.isLoading = true;
      console.log(this.mainInfo);
      this.$http
        .post("/SubDepartment/Trash", this.mainInfo)
        .then(response => {
          if (response.data.success === true) {
            this.$toast.success("Deleted successfully.", "Success", {
              position: "topRight"
            });
            this.isLoading = false;
            this.$router.push(
              `/admin/admin-departments/${this.mainInfo.departmentId}`
            );
          } else {
            this.isLoading = false;
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.isLoading = false;
            this.$toast.error(" Deleting Process Failed", "Error", {
              position: "topRight"
            });
          }
        });
    },
    editImage() {
      this.editedItem = Object.assign({}, this.viewItem);
      this.addImage(this.editedItem);
    },
    addImage(item) {
      this.editedIndexFiltered = this.dataFiltered.indexOf(item);
      this.viewItem = Object.assign({}, item);

      this.editedImage.productId = item.productId;
      this.editedImage.formFile = null;
      this.dialogImage = true;
      // document.getElementById("imageFile").click();
    },
    addImageToDb() {
      this.imageFormData = new FormData();

      this.imageFormData.append("productId", this.editedImage.productId);
      this.imageFormData.append("image", this.editedImage.formFile);

      this.saveImage();
    },
    saveImage() {
      this.isLoading = true;
      this.$http
        .post("/ProductMaster/Image", this.imageFormData)
        .then(response => {
          if (response.data.success === true) {
            this.$toast.success(response.data.message, "Success", {
              position: "topRight"
            });

            this.dialogImage = false;

            this.$http.get("/ProductMaster").then(response => {
              console.log(response.data.data);
              this.products = response.data.data.filter(
                a => a.subDepartmentId === this.subDepartmentId
              );

              this.products = this.products.reverse();

              this.changePage();
            });
          } else {
            this.isLoading = false;
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        })
        .catch(error => {
          this.isLoading = false;
          if (error.response.status) {
            this.$toast.error("Adding Process Failed", "Error", {
              position: "topRight"
            });
          }
        });
    }
  }
};
</script>
